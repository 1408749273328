<template>
  <div class="type_col flex flex-col items-start">
    <h4 class="type_col__name text-left text-xs sm:text-base">{{ t(typeName[props.type]) }} {{ props.type === 11 ? 'FIX GOLD' : '' }} </h4>
    <small class="dark:text-[#8d8d8d] text-xs sm:text-base">{{ props.date ? props.date.split(' ')[0].split('-').reverse().join('.') : '-' }}</small>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  type: Number,
  date: String
})
const { t } = useI18n()
const typeName = ref({
  11: 'depositTransaction',
  0: 'depositTransaction',
  1: 'withdrawType',
  2: 'exchangeType',
  3: 'directBonusType',
  4: 'globalPoolGoldType',
  5: 'globalPoolDiamondType',
  6: 'weeklyBonusType',
  7: 'depositFixBonusType',
  8: 'depositUsdBonusType',
  9: 'withdrawFixBonusType',
  10: 'withdrawUsdBonusType',
  12: 'autoExchangeType',
  13: 'withdrawFixGoldType',
  14: 'exchangeFixGoldType',
  15: 'depositCryptohouseType',
  16: 'exchangeFixOneType',
  17: 'Dexnet Node',
  18: 'Dexnet by usdt',
  19: 'Dexnet by usd',
  20: 'DEX Direct Bonus'
})
</script>

<style scoped>

.type_col__name{
  word-wrap: break-word;
  max-width: 100%;
}

.type_col{
  max-width: 100%;
}

@media (max-width: 400px) {
  .type_col__name {
    font-size: 10px;
  }
}

</style>
