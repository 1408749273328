<template>
  <div class="withdrawInfo w-full px-4 sm:px-6 md:px-8">
    <div class="card-bg" v-if="status === 5">
      <withdraw-verify @updTransaction="updTransaction" @successful="showNotification = true"/>
    </div>
    <div class="card" v-if="status === 0">
      <div class="card-bg">
        <h4 class="text-lg">Confirm</h4>
        <div class="mt-4 ml-1">
          <h6 class="mt-2">In order for your withdrawal request to be approved, you must send FIX tokens from your personal ERC20 wallet to the company's wallet.</h6>
          <div class="mt-2 flex flex-col">
            <small class="font-light">Wallet to send:</small>
            <small class="font-light">FIX - ERC20</small>
            <strong class="withdrawInfo__wallet text-orange-600">0x194de4a84892ca93b1f5ff583551ad27036f82ff</strong>
          </div>
        </div>
      </div>
      <withdraw-confirm @updTransaction="updTransaction" class="card-bg"/>
    </div>
    <div class="card-bg mt-4">
      <transactions-table :table-titles="getTableTransactionsTitlesDefault.slice(0, -1)" :transactions="transaction"/>
    </div>
    <notification :showNotification="showNotification" @close="showNotification = false">
      <p class="text-sm font-medium text-gray-900">{{ t('success') }}</p>
      <p class="mt-1 text-sm text-gray-500">{{ t('successWait') }}</p>
    </notification>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'
import WithdrawVerify from '@/components/withdrawVerify.vue'
import WithdrawConfirm from '@/components/withdrawConfirm.vue'
import Notification from '@/components/UI/notification.vue'
import axios from 'axios'
import { url } from '@/main'
import TransactionsTable from '@/components/transactionsTable.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const store = useStore()
const { getTableTransactionsTitlesDefault } = store.getters
const status = ref('')
const route = useRoute()
const transaction = ref([])
const showNotification = ref(false)
const fetchTransaction = () => {
  axios.get(`${url}/api/payments/transaction/${route.params.id}`, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true })
    .then((response) => {
      status.value = response.data.data.status
      transaction.value = [
        {
          type: response.data.data.type,
          dollars: response.data.data.data || response.data.data.amount_in_usd ? response.data.data.amount_in_usd : null,
          token: Number(response.data.data.amount_in_fix).toFixed(4),
          data: response.data.data.created_at.split('.')[0].split('T').join(' '),
          status: response.data.data.status,
        }
      ]
      if (response.data.data.status === 10) {
        setTimeout(() => {
          fetchTransaction()
        }, 10000)
      }
    })
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem('tokenAuth')
        document.location.href = document.location.origin
      }
    })
}
onBeforeMount(() => {
  fetchTransaction()
})
const updTransaction = () => {
  fetchTransaction()
}
</script>

<style lang="scss" scoped>

@media (max-width: 430px) {
  .withdrawInfo__wallet {
    font-size: 14px;
  }
}

@media (max-width: 385px) {
  .withdrawInfo__wallet {
    font-size: 13px;
  }
}


@media (max-width: 360px) {
  .withdrawInfo__wallet {
    font-size: 13px;
  }
}


</style>
