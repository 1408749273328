export default {
  en: {
    broadcast: 'Broadcasts',
    myOffice: 'My office',
    deposit: 'Deposit',
    depositProfixone: 'Deposit ProfiXone',
    FIXTokenCheck: 'FIX token Account',
    partnershipAccount: 'Partnership Account',
    partnershipAccountProfix: 'Partnership ProfiXone',
    partnershipAccountDexnet: 'Partnership Dexnet',
    transactions: 'Transactions',
    withdrawl: 'Withdraw',
    withdrawlProfixone: 'Withdraw Profixone',
    partners: 'Partners',
    settings: 'Settings',
    globalpool: 'Global Pool',
    cryptohouse: 'ProfixHome',
    northencyprus: 'Northen Cyprus',
    spain: 'Spain',
    marketing: 'Marketing Tools',
    manager: 'Personal Manager',
    partnerAgreements: 'Partner Agreements',
    withdraw: 'Withdrawal',
    withdrawDexnet: 'Withdraw Dexnet',
    adminDashboard: 'Admin Dashboard',
    manageUsers: 'Manage Users',
    deposits: 'Deposits',
    promotion: 'Promotion',
    verifications: 'Verifications',
    buyDexNode: 'Buy DEX Node',
    Birthdays: 'Birthdays'
  },
  ru: {
    broadcast: 'Трансляции',
    myOffice: 'Мой офис',
    deposit: 'Депозит',
    depositProfixone: 'Депозит ProfiXone',
    FIXTokenCheck: 'FIX токен счет',
    partnershipAccount: 'Партнерский аккаунт',
    partnershipAccountProfix: 'Партнерский ProfiXone',
    partnershipAccountDexnet: 'Партнёрский Dexnet',
    transactions: 'Транзакции',
    withdrawl: 'Вывод',
    withdrawlProfixone: 'Вывод Profixone',
    withdrawDexnet: 'Вывод Dexnet',
    partners: 'Партнеры',
    settings: 'Настройки',
    globalpool: 'Global pool',
    cryptohouse: 'ProfixHome',
    northencyprus: 'Северный Кипр',
    spain: 'Испания',
    marketing: 'Маркетинг',
    manager: 'Персональный Менеджер',
    partnerAgreements: 'Партнерское соглашение',
    withdraw: 'Транзакции',
    adminDashboard: 'Панель управления',
    manageUsers: 'Пользователи',
    deposits: 'Депозиты',
    promotion: 'Promotion',
    verifications: 'Верификации',
    buyDexNode: 'Купить DEX Node',
    Birthdays: 'Birthdays'

  },
  es: {
    broadcast: 'Emisión',
    myOffice: 'Mi Oficina',
    deposit: 'Depositar',
    depositProfixone: 'Depositar ProfiXone',
    FIXTokenCheck: 'Cuenta FIX Token',
    partnershipAccount: 'Cuenta de Asociación',
    partnershipAccountProfix: 'Cuenta de Asociación ProfiXone',
    partnershipAccountDexnet: 'Cuenta de Asociación Dexnet',
    transactions: 'Transacciones',
    withdrawl: 'Retiro',
    withdrawlProfixone: 'Retiro Profixone',
    withdrawDexnet: 'Retiro Dexnet',
    partners: 'Socios',
    settings: 'Ajustes',
    globalpool: 'Global pool',
    cryptohouse: 'ProfixHome',
    northencyprus: 'Chipre del Norte',
    spain: 'España',
    marketing: 'Herramientas de Marketing',
    manager: 'Gerente de Personal',
    partnerAgreements: 'Acuerdos de Socios',
    withdraw: 'Transacciones',
    adminDashboard: 'Panel de Administración',
    manageUsers: 'Administrar los Usuarios',
    deposits: 'Depósitos',
    promotion: 'Promotion',
    verifications: 'Verificaciones ',
    buyDexNode: 'Comprar DEX Node',
    Birthdays: 'Birthdays'

  }
}
