export default {
  en: {
    ourBalance: 'Our balance:',
    dexnetBuy: 'Buy',
    dexnetYes: 'Yes',
    dexnetNo: 'No',
    dexnetInfo:
      "Decentralized, Autonomous, Secure Next Generation Internet with SPACEX's SWARM Low Earth Orbit Satellite Network",
    dexnetCapInternet: 'True DEX internet',
    dexnetInfoInternet: `Communication between Dex Node devices is provided by two
    independent channels for the transmission of encryption keys and
    other information requiring increased security.`,
    dexnetCapChannel: `Own high-frequency channel`,
    dexnetInfoChannel: `Autonomous communication channel at a frequency of 433 MHz,
    thanks to which Dex Node devices detect each other within a
    radius of up to 10 km. (without putting a signal into orbit)
    and form a stable and secure communication channel by
    overlapping each other with a terrestrial radio signal
    according to the honeycomb principle.`,
    dexnetCapLow: 'Low orbit satellite channel',
    dexnetInfoLow: `The second communication link is provided by direct connection
    of the Dex Node devices to Space X's Swarm satellite system.
    The Dex Node connects via a compact antenna to the nearest
    Swarm low-orbit satellite for two-way communication. The Space
    X Swarm satellites form a stable link with the Dex Node,
    covering the planet 100%.`,
    dexnetInfoParallel: `In parallel, the nodes use the public Internet to transmit
    large volume encrypted data packets.`,
    dexnetCapList: `The whole world in a small device`,
    dexnetListItem1: 'СPU: 4-core Rockship RK3566',
    dexnetListItem2: 'Info: Full Color IPS LCD',
    dexnetListItem3: 'Ethernet: RJ45, Wi-Fi',
    dexnetListItem4: 'Satellite system: SWARM by SpaceX',
    dexnetListItem5: 'Cryptocurrency mining with high performance',
    dexnetListItem6: 'Info: Full Color IPS LCD Space X SWARM',
    dexnetListItem7: 'Dex Wan: own high-frequency terrestrial channel 915 MHz, 5.5 km',
    dexnetListItemGSM: 'c e-sim DEX Mobile',
    dexnetNodeInfo: `Is a physical device with a high-performance processor, large SSD
    memory, a unique NFT authenticator and its own independent wireless
    communication channels`,
    dexnetCommunicationChannels: `communication<br />
    channels`,
    dexnetSSDDrive: `SSD-<br />
    drive`,
    buyDevice: 'Buy device',
    instructionsFixOneTitle: 'Purchase device for FixOne token',
    instructionsFixOne1:
      'If you want to purchase a device for {price} FixOne token, click "Buy" and confirm the transaction.',
    instructionsFixOne2: `After you make the purchase, you will need to contact a <a class="text-orange-500" href="https://t.me/profixone_support">MANAGER</a> to receive a promocode for your purchase. Activate this promocode by following the  <a class="text-orange-500" href="https://dexnet.one/">link</a> and receive the tracking number for delivery.`,
    instructionsFixOneConfirm:
      'Are you sure you want to purchase Dexnet Node ?',
    instructionsPartnershipTitle: 'Purchase device for USDT Partnership',
    instructionsPartnership1:
      'If you want to purchase a device for 1500 USDT Partnership, click "Buy" and confirm the transaction.',
    instructionsPartnership2: `After you make the purchase, you will need to contact a <a class="text-orange-500" href="https://t.me/profixone_support">MANAGER</a> to receive a promocode for your purchase. Activate this promocode by following the  <a class="text-orange-500" href="https://dexnet.one/">link</a> and receive the tracking number for delivery.`,
    instructionsUsdtTitle: 'How to purchase a device with {token}?',
    instructionsUsdt1: 'Step 1: Access your {token} wallet.',
    instructionsUsdt2: 'Step 2: Send {token} to the following address:',
    instructionsUsdt3:
      'Step 3: After you send the {token}, enter the transaction HASH or provide a screenshot of the transaction from your wallet, then click the "Confirm" button.',
    instructionsUsdt4:
      'Step 4: Contact the <a class="text-orange-500" href="https://t.me/profixone_support">MANAGER</a> to receive a promocode for your purchase. Activate this promocode by following the  <a class="text-orange-500" href="https://dexnet.one/">link</a> and receive the tracking number for delivery.',
    operationInProgress: 'Operation in progress.',
    operationInProgressInfo:
      'Contact the MANAGER to receive a promocode for your purchase. Activate this promocode by following the  <a class="text-orange-500" href="https://dexnet.one/">link</a> and receive the tracking number for delivery.'
  },
  ru: {
    ourBalance: 'Баланс:',
    dexnetBuy: 'Купить',
    dexnetYes: 'Да',
    dexnetNo: 'Нет',
    dexnetInfo:
      "Decentralized, Autonomous, Secure Next Generation Internet with SPACEX's SWARM Low Earth Orbit Satellite Network",
    dexnetCapInternet: 'True DEX internet',
    dexnetInfoInternet: `Communication between Dex Node devices is provided by two
    independent channels for the transmission of encryption keys and
    other information requiring increased security.`,
    dexnetCapChannel: `Own high-frequency channel`,
    dexnetInfoChannel: `Autonomous communication channel at a frequency of 433 MHz,
    thanks to which Dex Node devices detect each other within a
    radius of up to 10 km. (without putting a signal into orbit)
    and form a stable and secure communication channel by
    overlapping each other with a terrestrial radio signal
    according to the honeycomb principle.`,
    dexnetCapLow: 'Low orbit satellite channel',
    dexnetInfoLow: `The second communication link is provided by direct connection
    of the Dex Node devices to Space X's Swarm satellite system.
    The Dex Node connects via a compact antenna to the nearest
    Swarm low-orbit satellite for two-way communication. The Space
    X Swarm satellites form a stable link with the Dex Node,
    covering the planet 100%.`,
    dexnetInfoParallel: `In parallel, the nodes use the public Internet to transmit
    large volume encrypted data packets.`,
    dexnetCapParams: `The whole world in a small device`,
    dexnetCapList: `The whole world in a small device`,
    dexnetListItem1: 'СPU: 4-core Rockship RK3566',
    dexnetListItem2: 'Info: Full Color IPS LCD',
    dexnetListItem3: 'Ethernet: RJ45, Wi-Fi',
    dexnetListItem4: 'Satellite system: SWARM by SpaceX',
    dexnetListItem5: 'Cryptocurrency mining with high performance',
    dexnetListItem6: 'Info: Full Color IPS LCD Space X SWARM',
    dexnetListItem7: 'Dex Wan: own high-frequency terrestrial channel 915 MHz, 5.5 km',
    dexnetListItemGSM: 'c e-sim DEX Mobile',
    dexnetNodeInfo: `Is a physical device with a high-performance processor, large SSD
    memory, a unique NFT authenticator and its own independent wireless
    communication channels`,
    dexnetCommunicationChannels: `communication<br />
    channels`,
    dexnetSSDDrive: `SSD-<br />
    drive`,
    instructionsFixOneTitle: 'Покупка устройства за FixOne token',
    instructionsFixOne1:
      'Если Вы хотите приобрести устройство за {price} FixOne token нажмите купить и подтвердите операцию.',
    instructionsFixOne2: `После того как Вы купите Вам нужно будет связаться с <a class="text-orange-500" href="https://t.me/profixone_support">МЕНЕДЖЕРОМ</a>  для получения ваучера на покупку. Данный ваучер активируйте по  <a class="text-orange-500" href="https://dexnet.one/">ссылке</a> и получите трек номер отслеживания доставки.`,
    instructionsFixOneConfirm: 'Вы уверены что хотите приобрести Dexnet Node ?',
    instructionsPartnershipTitle: 'Покупка устройства за USDT Partnership',
    instructionsPartnership1:
      'Если Вы хотите приобрести устройство за 1500 USDT Partnership нажмите купить и подтвердите операцию.',
    instructionsPartnership2: `После того как Вы купите Вам нужно будет связаться с <a class="text-orange-500" href="https://t.me/profixone_support">МЕНЕДЖЕРОМ</a>  для получения ваучера на покупку. Данный ваучер активируйте по <a class="text-orange-500" href="https://dexnet.one/">ссылке</a> и получите трек номер отслеживания доставки.`,
    instructionsUsdtTitle: 'Как купить устройство за {token}?',
    instructionsUsdt1: '1 Шаг: Зайдите в Ваш кошелек {token}.',
    instructionsUsdt2: '2 Шаг: Отправьте {token} на адрес:',
    instructionsUsdt3:
      '3 Шаг: После того, как вы отправите {token}, введите HASH транзакции либо приложите скриншот Транзакции из вашего кошелька и нажмите кнопку Подтвердить.',
    instructionsUsdt4:
      '4 Шаг: Свяжитесь с  <a class="text-orange-500" href="https://t.me/profixone_support">МЕНЕДЖЕРОМ</a> для получения ваучера на покупку. Данный ваучер активируйте по <a class="text-orange-500" href="https://dexnet.one/">ссылке</a> и получите трек номер отслеживания доставки.'
  },
  es: {
    ourBalance: 'Nuestro equilibrio:',
    dexnetBuy: 'Сomprar',
    dexnetYes: 'Sí',
    dexnetNo: 'No hay',
    dexnetInfo:
      "Decentralized, Autonomous, Secure Next Generation Internet with SPACEX's SWARM Low Earth Orbit Satellite Network",
    dexnetCapInternet: 'True DEX internet',
    dexnetInfoInternet: `Communication between Dex Node devices is provided by two
    independent channels for the transmission of encryption keys and
    other information requiring increased security.`,
    dexnetCapChannel: `Own high-frequency channel`,
    dexnetInfoChannel: `Autonomous communication channel at a frequency of 433 MHz,
    thanks to which Dex Node devices detect each other within a
    radius of up to 10 km. (without putting a signal into orbit)
    and form a stable and secure communication channel by
    overlapping each other with a terrestrial radio signal
    according to the honeycomb principle.`,
    dexnetCapLow: 'Low orbit satellite channel',
    dexnetInfoLow: `The second communication link is provided by direct connection
    of the Dex Node devices to Space X's Swarm satellite system.
    The Dex Node connects via a compact antenna to the nearest
    Swarm low-orbit satellite for two-way communication. The Space
    X Swarm satellites form a stable link with the Dex Node,
    covering the planet 100%.`,
    dexnetInfoParallel: `In parallel, the nodes use the public Internet to transmit
    large volume encrypted data packets.`,
    dexnetCapParams: `The whole world in a small device`,
    dexnetCapList: `The whole world in a small device`,
    dexnetListItem1: 'СPU: 4-core Rockship RK3566',
    dexnetListItem2: 'Info: Full Color IPS LCD',
    dexnetListItem3: 'Ethernet: RJ45, Wi-Fi',
    dexnetListItem4: 'Satellite system: SWARM by SpaceX',
    dexnetListItem5: 'Cryptocurrency mining with high performance',
    dexnetListItem6: 'Info: Full Color IPS LCD Space X SWARM',
    dexnetListItem7: 'Dex Wan: own high-frequency terrestrial channel 915 MHz, 5.5 km',
    dexnetListItemGSM: 'c e-sim DEX Mobile',
    dexnetNodeInfo: `Is a physical device with a high-performance processor, large SSD
    memory, a unique NFT authenticator and its own independent wireless
    communication channels`,
    dexnetCommunicationChannels: `communication<br />
    channels`,
    dexnetSSDDrive: `SSD-<br />
    drive`,
    instructionsFixOneTitle: 'Compra de dispositivo con token FixOne',
    instructionsFixOne1:
      'Si desea adquirir un dispositivo por {price} token FixOne, haga clic en "Comprar" y confirme la operación.',
    instructionsFixOne2: `Después de realizar la compra, deberá ponerse en contacto con un <a class="text-orange-500" href="https://t.me/profixone_support">GERENTE</a>  para recibir un cupón de compra. Active este cupón siguiendo el <a class="text-orange-500" href="https://dexnet.one/">enlace</a> y obtenga el número de seguimiento para la entrega.`,
    instructionsFixOneConfirm:
      '¿Estás seguro de que quieres comprar Dexnet Node?',
    instructionsPartnershipTitle: 'Compra de dispositivo con USDT Partnership',
    instructionsPartnership1:
      'Si desea adquirir un dispositivo por 1500 USDT Partnership, haga clic en "Comprar" y confirme la operación.',
    instructionsPartnership2: `Después de realizar la compra, deberá ponerse en contacto con un <a class="text-orange-500" href="https://t.me/profixone_support">GERENTE</a> para recibir un cupón de compra. Active este cupón siguiendo el <a class="text-orange-500" href="https://dexnet.one/">enlace</a> y obtenga el número de seguimiento para la entrega.`,
    instructionsUsdtTitle: '¿Cómo comprar un dispositivo con {token}?',
    instructionsUsdt1: 'Paso 1: Accede a tu billetera de {token}.',
    instructionsUsdt2: 'Paso 2: Envía {token} a la siguiente dirección:',
    instructionsUsdt3:
      'Paso 3: Después de enviar los {token}, ingresa el HASH de la transacción o adjunta una captura de pantalla de la transacción desde tu billetera y haz clic en el botón "Confirmar"',
    instructionsUsdt4:
      'Paso 4: Ponte en contacto con el <a class="text-orange-500" href="https://t.me/profixone_support">GERENTE</a> para recibir un cupón de compra. Activa este cupón siguiendo el <a class="text-orange-500" href="https://dexnet.one/">enlace</a> y obtén el número de seguimiento para la entrega.',
    operationInProgress: 'Operación en curso.',
    operationInProgressInfo:
      'Ponte en contacto con el <a class="text-orange-500" href="https://t.me/profixone_support">GERENTE</a> para recibir un cupón de compra. Activa este cupón siguiendo el <a class="text-orange-500" href="https://dexnet.one/">enlace</a> y obtén el número de seguimiento para la entrega.'
  }
}
