export default {
  en: {
    timing: 'Your request for a deposit of funds will be processed in accordance with the rules within 24 hours',
    chooseTypeDeposit: 'Deposit',
    instructionTitle: 'How do I make a deposit to FIX Gold? Follow the instructions!',
    stepOneInstruction: 'Step 1: Go to the website',
    stepTwoInstruction: 'Step 2: Choose an item',
    stepThreeInstruction: 'Step 3: Write to the Manager',
    stepForInstruction: 'Step 4: Make a deposit',
    attention: 'Attention!',
    minFirstDeposit: '• The first deposit cannot be less than {token} USDT.',
    minNextDepostit: '• Subsequent replenishment from {token} USDT.',
    minDepositAlert: 'The minimum deposit in FIX {token} Token is',
    depositUSDT: 'You will deposit USDT',
    willGetFixGold: 'You will get FIX Gold Tokens',
    willGetFixOne: 'You will get FIX One Tokens',
    commission: 'Commission 1%',
    growthCommission: 'Growth Commission',
    totalNeedDeposit: 'Total you need to Deposit <br> (with Commission)',
    totalWarning: 'Total with 1% Commission For Deposit and Growth Commission',
    checkout: 'Checkout',
    checkoutVariablesOne: 'Get home delivery',
    checkoutVariablesTwo: 'Leave in store for sale',
    infoDelivery: 'Information and delivery costs',
    infoDeliveryTxt: `<p>The shipment is made through the international company DHL Express <br>
      Over $10,000 free shipping Shipping costs: <br>
      * 1% - payment for insurance of the goods <br>
      * Payment for the delivery depends on the country and city. That's why the final amount you will know from the manager or when sending the goods. <br>
      After shipping you will receive a receipt where you can see all costs and you will also receive a code to track the delivery status </p>`,
    depositTransactionTitle: 'Deposit Transaction',
    annualIncome: 'Annual income',
    incomePerWeek: 'Income per week',
    network: 'Network',
    ensuringGrowth: 'Ensuring growth',
    numbersOfTokens: 'Numbers of tokens',
    launchingASmartContract: 'Launching a smart contract',
    minimumDeposit: 'Minimum deposit',
    blockingWithdrawalOfFunds: 'Blocking withdrawal of funds',
    affiliateProgram: 'Affiliate program',
    depositInsurance: 'Deposit insurance',
    ensuringGrowthValue: 'Smart Contract',
    blockingWithdrawalOfFundsValue: '12 months',
    affiliateProgramValue: 'Available',
    depositInsuranceValue: 'Full(Jewelry)',
    partnerProgram: 'Partner Program',
    partnerProgramValue: 'Available',
    blockingWithdrawalOfFundsOneValue: 'NO',
    stepOneConfirm: 'STEP 1: Deposit Instruction',
    howToDeposit: 'How To Deposit?',
    below: 'To deposit via USDT, follow the instructions below:',
    steOneDeposit: '1 Step: Go to your personal USDT wallet',
    stepTwoDeposit: '2 Step: Send USDT to this address:',
    stepThreeDeposit: '3 Step: Only after paying USDT to the specified address, click the "CONFIRM" button.',
    blockchainConfirmations: 'After 12 blockchain confirmations, you will receive FIX token to your wallet',
    totalYouNeed: 'Total you need to Deposit (with Commission)',
    stepTwoConfirm: 'STEP 2: After sending your USDT, enter your Transaction HASH You can find your transaction HASH in your wallet (Trust Wallet, Metamask and etc)',
    hashTransaction: 'Your Transaction HASH',
    screenshotTransaction: 'or upload your Transaction screenshot',
    depositOneVideo: 'https://www.youtube.com/embed/dw1xEug0gHQ',
    depositGoldVideo: 'https://www.youtube.com/embed/2iFDR28c4CY',
    // jewerlySaleAgreement: 'Jewerly sale Agreement',
  },
  ru: {
    timing: 'Ваш запрос на депозит средств будет обрабатываться в соответствии с правилами в течение 24 часов',
    chooseTypeDeposit: 'Депозит',
    instructionTitle: 'Как сделать депозит в FIX Gold? Следуйте инструкции!',
    stepOneInstruction: '1 Шаг: Зайдите на сайт',
    stepTwoInstruction: '2 Шаг: Выберите товар',
    stepThreeInstruction: '3 Шаг: Напишите Менеджеру',
    stepForInstruction: '4 Шаг: Сделайте депозит',
    attention: 'Внимание!',
    minFirstDeposit: '• Первый депозит не может быть меньше {token} USDT.',
    minNextDepostit: '• Последующее пополнение от {token} USDT.',
    minDepositAlert: 'Минимальный депозит в FIX {token} Token составляет',
    depositUSDT: 'Вы делаете депозит USDT',
    willGetFixGold: 'Вы получите FIX Gold Токены',
    willGetFixOne: 'Вы получите FIX One Токены',
    commission: 'Коммисия 1%',
    growthCommission: 'Комиссия Роста',
    totalNeedDeposit: 'Итого сумма депозита: <br> (с комиссией)',
    totalWarning: 'Итого с комиссией 1% и комиссией роста',
    checkout: 'Оформление заказа',
    checkoutVariablesOne: 'Получить домой с доставкой',
    checkoutVariablesTwo: 'Оставить в магазине на продажу',
    infoDelivery: 'Информация и стоимость доставки',
    infoDeliveryTxt: `<p>Отправка товара производится через международную компанию DHL Express <br>
                      От 10.000$ доставка бесплатна<br>
                      Расходы по доставке:<br>
                      * 1% - оплата за страхование товара<br>
                      * Оплата за саму доставку зависит от страны и города. Поэтому финальную сумму можно будет узнать у менеджера или уже при отправке товара.<br>
                      После отправке вы получите чек где сможете увидеть все расходы и так же получите код для отслеживания статуса доставки.</p>`,
    depositTransactionTitle: 'Транзакции Депозита',
    annualIncome: 'Годовой доход',
    incomePerWeek: 'Доход в неделю',
    network: 'Сеть',
    ensuringGrowth: 'Обеспечение роста',
    numbersOfTokens: 'Колличество токенов',
    launchingASmartContract: 'Запуск смарт-контракта',
    minimumDeposit: 'Минимальный депозит',
    blockingWithdrawalOfFunds: 'Блокировка вывода средств',
    affiliateProgram: 'Партнерская программа',
    depositInsurance: 'Стразование вкладов',
    ensuringGrowthValue: 'Смарт-контракт',
    blockingWithdrawalOfFundsValue: '12 месяцев',
    affiliateProgramValue: 'Доступная',
    depositInsuranceValue: 'Полный (ювелирные изделия)',
    partnerProgram: 'Партнерская программа',
    partnerProgramValue: 'Доступна',
    blockingWithdrawalOfFundsOneValue: 'НЕТ',
    stepOneConfirm: 'ШАГ 1: Инструкция Депозита',
    howToDeposit: 'Как сделать Депозит?',
    below: 'Чтобы пополнить счет с USDT, следуйте инструкции ниже:',
    steOneDeposit: '1 Шаг: Зайдите в Ваш кошелек USDT',
    stepTwoDeposit: '2 Шаг: Отправьте USDT на этот адрес:',
    stepThreeDeposit: '3 Шаг: Только после того, как Вы отправите USDT, нажимайте кнопку Подтвердить.',
    blockchainConfirmations: 'После 12 подтверждений блокчейна вы получите FIX токены на свой кошелек',
    totalYouNeed: 'Итого сумма депозита: (с комиссией)',
    stepTwoConfirm: 'ШАГ 2: После отправки Ваших USDT, введите HASH Транзакции Вы можете найти HASH Транзакции в своем кошельке (например Trust Wallet, Metamask и т.п.)',
    hashTransaction: 'Ваш HASH Транзакции',
    screenshotTransaction: 'или загрузите скриншот Транзакции',
    depositOneVideo: 'https://www.youtube.com/embed/an1Xzs0gG5I',
    depositGoldVideo: 'https://www.youtube.com/embed/RTyc4agc11g',
    // jewerlySaleAgreement: 'Договор купли-продажи',
  },
  es: {
    timing: 'Su solicitud de depósito de fondos se procesará de acuerdo con las reglas dentro de las 24 horas',
    chooseTypeDeposit: 'Depósito',
    instructionTitle: '¿Cómo hago un depósito en FIX Gold? Sigue las instrucciones',
    stepOneInstruction: 'Paso 1: Ir al sitio web',
    stepTwoInstruction: 'Paso 2: Seleccionar el artículo',
    stepThreeInstruction: 'Paso 3: Gestor de correo electrónico',
    stepForInstruction: 'Paso 4: Hacer un depósito',
    attention: 'Atención!',
    minFirstDeposit: '• El primer depósito no puede ser inferior a {token} USDT.',
    minNextDepostit: '• Reposición posterior de {token} USDT.',
    minDepositAlert: 'El depósito mínimo para FIX {token} Token es de',
    depositUSDT: 'Depositará USDT',
    willGetFixGold: 'Conseguirá FIX Gold Tokens',
    willGetFixOne: 'Conseguirá FIX One Tokens',
    commission: 'Comsión 1%',
    growthCommission: 'Comisión de crecimiento',
    totalNeedDeposit: 'Total que necesita depositar <br> (con comisión)',
    totalWarning: 'Total con 1% de comisión por depósito y comisión de crecimiento',
    checkout: 'Checkout',
    checkoutVariablesOne: 'Llevar a domicilio',
    checkoutVariablesTwo: 'Dejar en la tienda para la venta',
    infoDelivery: 'Costes de información y entrega',
    infoDeliveryTxt: `<p>El artículo se envía a través de DHL Express <br>
                         Más de 10.000 dólares de envío gratuito Gastos de envío: <br>
                         * 1% - tasa de seguro <br>
                         * El pago de la entrega en sí depende del país y la ciudad. Por favor, pregunte a su gestor el importe final o pida un presupuesto en el momento del envío de la mercancía. <br>
                         Recibirá un recibo con todos los gastos y también recibirá un código para seguir el estado de la entrega</p>`,
    depositTransactionTitle: 'Transacciones de depósito',
    annualIncome: 'Ingreso anual',
    incomePerWeek: 'Ingresos per semana',
    network: 'Red',
    ensuringGrowth: 'Asegurar el crecimiento',
    numbersOfTokens: 'Números de toke',
    launchingASmartContract: 'Lanzamiento de contrato inteligente',
    minimumDeposit: 'Depósito mínimo',
    blockingWithdrawalOfFunds: 'Bloqueo de retiros',
    affiliateProgram: 'Programa de afiliación',
    depositInsurance: 'Deposit insurance',
    ensuringGrowthValue: 'Contrato inteligente',
    blockingWithdrawalOfFundsValue: '12 meses',
    affiliateProgramValue: 'fácil',
    depositInsuranceValue: 'Completo (joyas)',
    partnerProgram: 'Programa de afiliados',
    partnerProgramValue: 'Disponible',
    blockingWithdrawalOfFundsOneValue: 'NO HAY',
    stepOneConfirm: 'PASO 1: Instrucción de depósito',
    howToDeposit: 'Cómo depositar?',
    below: 'Para depositar a través de USDT, sigue las instrucciones de abajo:',
    steOneDeposit: '1 paso: Vaya a su Billetera USDT',
    stepTwoDeposit: 'Paso 2: envíe USDT a esta dirección:',
    stepThreeDeposit: 'Paso 3: Solo después de pagar USDT a la dirección especificada, haga clic en el botón "CONFIRMAR".',
    blockchainConfirmations: 'Después de 12 confirmaciones de blockchain, recibirá la FIX token en su Billetera',
    totalYouNeed: 'Total que necesita depositar (con comisión)',
    stepTwoConfirm: 'PASO 2: Después de enviar sus USDTs, Ingrese su HASH de transacción Puede encontrar su HASH de transacción en su Billetera (Trust Wallet, Metamask, etc.)',
    hashTransaction: 'Su HASH de transacción',
    screenshotTransaction: 'o cargue la captura de pantalla de su transacción',
    depositOneVideo: 'https://www.youtube.com/embed/Hnyg97y2qsc',
    depositGoldVideo: 'https://www.youtube.com/embed/2iFDR28c4CY',
    // jewerlySaleAgreement: 'Acuerdo de Venta de Joyas',
  }
}
